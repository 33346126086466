import React, { useEffect, useState } from "react";
import "../css/style.css";
import searchIcon from "../assests/images/search.svg";
import bell from "../assests/images/bell.png";
import defaultAvatar from "../assests/images/default-avatar.svg";
import solidDropdown from "../assests/images/solid-dropdown.svg";
import txLogo from "../assests/images/tx-logo.png";
import emptyIcon from "../assests/images/emptyIcon.svg";
import solidDropUp from "../assests/images/solid-dropUp.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MobileProfileNav from "./MobileProfileNav";
import { GetUserDetails } from "../RequestHandlers/User";
import { GetUserNotifications } from "../RequestHandlers/Notification";
import { formatDate } from "./FormatDate";

interface PProp {
  fromSupport?: any;
  logoutClick?: any;
  offSearchBtn?: Boolean;
  searchField?: any;
}

export default function ProfileNav({
  fromSupport,
  logoutClick,
  // offSearchBtn,
  searchField,
}: PProp) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [notifications, setNotification] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [offSearchBtn, setOffSearchBtn] = useState(false);
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const [showNotification, setShowNotification] = useState(false);
  const togglenoti = () => {
    setShowNotification(!showNotification);
  };
  const navigate = useNavigate();
  const location = useLocation();

  // @ts-ignore
  let newName;
  // @ts-ignore
  if (userInfo.name) {
    // @ts-ignore
    let name = userInfo.name.split(" ");
    newName = name[0];
  }

  // @ts-ignore
  const truncateText = (text, maxLength) => {
    let charCount = 0;
    let truncatedText = "";

    for (let char of text) {
      if (char !== " ") {
        charCount++;
      }
      if (charCount <= maxLength) {
        truncatedText += char;
      } else {
        truncatedText += "...";
        break;
      }
    }

    return truncatedText;
  };

  // @ts-ignore
  const UserDetails = async () => {
    try {
      const userDetails = await GetUserDetails();
      setUserInfo(userDetails);
      console.log(userDetails);
      
    } catch (error) {
      console.log(error);
    }
  };

  const userNotifications = async () => {
    try {
      const noti = await GetUserNotifications();
      // console.log(noti);
      setNotification(noti.items);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    UserDetails();
    userNotifications();
  }, []);

  const handleSearchClick = () => {
    if (location.pathname === "/blogDashboard") {
      setShowSearch(true);
      setOffSearchBtn(true);
    } else {
      navigate("/blogDashboard");
      setShowSearch(true);
      setOffSearchBtn(true);
    }
  };

  if (isMobile) {
    return (
      <MobileProfileNav
        fromSupport={fromSupport}
        logoutClick={logoutClick}
        newName={newName}
      />
    );
  } else {
    return (
      <section className="profile-nav">
        <p className="user-name">Welcome, {newName} ✨</p>
        <div className="rightHand-cont">
          <div className="search-cont">
            {showSearch ? searchField : <></>}
            {offSearchBtn ? (
              <></>
            ) : (
              <button
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
                onClick={handleSearchClick}
              >
                <img src={searchIcon} alt="" />
              </button>
            )}
          </div>
          <div className="notification-cont">
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => togglenoti()}
            >
              <img
                src={bell}
                alt=""
                style={{ width: 24, height: 24, cursor: "pointer" }}
              />
            </button>

            {showNotification && (
              <div className="notification-drop">
                <div
                  className="notification-h"
                  style={{ border: notifications.length > 0 ? "none" : "" }}
                >
                  Notifications
                </div>
                <div className="notification-body">
                  {notifications.length === 0 ? (
                    <div className="card-empty">
                      <img src={emptyIcon} alt="" />
                      <h4>No notifications</h4>
                      <p>
                        We’ll let you know when there’s <br /> anything new!
                      </p>
                    </div>
                  ) : (
                    <>
                      {/* @ts-ignore */}
                      {notifications.map((item, index) => {
                        return (
                          <div
                            className="notification-item"
                            onClick={() => setShowNotification(false)}
                            style={{
                              border:
                                index === notifications.length - 1
                                  ? "none"
                                  : "",
                              marginBottom:
                                index === notifications.length - 1
                                  ? "none"
                                  : "",
                            }}
                          >
                            <img src={txLogo} alt="" />
                            <div className="text-date-wrap">
                              <p style={{ lineClamp: 1, display: "" }}>
                                {/* @ts-ignore */}
                                {item.title}
                              </p>
                              {/* @ts-ignore */}
                              <p>{formatDate(item.created_at)}</p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="profile-wrap">
            <img
              // @ts-ignore
              src={userInfo.avatar === null ? defaultAvatar : userInfo.avatar}
              alt=""
              style={{
                width: 32,
                height: 32,
                borderRadius: 100,
                objectFit: "cover",
              }}
            />
            <button className="profile-drop" onClick={handleDropdownToggle}>
              {/* @ts-ignore */}
              {userInfo.name}
              <img src={isDropdownOpen ? solidDropUp : solidDropdown} alt="" />
            </button>
            {isDropdownOpen && (
              <div className="ppr-card">
                <button
                  className="ppr-btn"
                  onClick={() =>
                    navigate("/profileScreen", { state: { fromBlog: true } })
                  }
                >
                  Profile details
                </button>
                <button
                  className="ppr-btn"
                  onClick={() =>
                    navigate("/userSupscription", { state: { fromBlog: true } })
                  }
                >
                  Subscription
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}
