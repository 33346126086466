import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../css/style.css";
import Toast, { handleToast } from "../components/Toast";
import {
  ResendOtp,
  VerifyEmail,
  SendEmailVerify,
} from "../RequestHandlers/Authentication";
import Cookies from "js-cookie";
import { CredentailsContext } from "../RequestHandlers/CredentailsContext";

export default function OtpScreen() {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [fields, setFields] = useState(Array(6).fill("")); // Initialize with 6 empty strings
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const email = location.state?.email || "";

  const ref_input1 = useRef();
  const ref_input2 = useRef();
  const ref_input3 = useRef();
  const ref_input4 = useRef();
  const ref_input5 = useRef();
  const ref_input6 = useRef();
  const refs = [
    ref_input1,
    ref_input2,
    ref_input3,
    ref_input4,
    ref_input5,
    ref_input6,
  ];

  const sendEmailverify = async () => {
    const handle = await SendEmailVerify({
      email: email,
    });
    console.log(handle);
  };


  const validateOtp = async () => {
    setLoading(true);
    const otp = fields.join("");
    if (otp === "") {
      setLoading(false);
      handleToast("Type In The Otp", "error");
    } else {
      const handle = await VerifyEmail({
        otp: otp,
      });
      // setloading(false);
      if (handle.status === "active") {
        handleToast("OTP Verified", "success");
        navigate("/subscriptionScreen1"); // Adjust as needed
      } else {
        handleToast("Incorrect Code", "error");
      }
    }
  };

  // useEffect(() => {
  //   sendEmailverify();
  // }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(interval);
          setResendDisabled(false);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(interval);
          setResendDisabled(false);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);
  const otpResend = async () => {
    const resend = await ResendOtp();
    console.log(resend);
  };
  const handleResend = () => {
    setSeconds(60);
    setResendDisabled(true);
    sendEmailverify();
  };

  const focusNextField = (index: any) => {
    if (index < refs.length - 1) {
      // @ts-ignore
      refs[index + 1].current.focus();
    }
  };

  const focusPrevField = (index: any) => {
    if (index > 0) {
      // @ts-ignore
      refs[index - 1].current.focus();
    }
  };

  const handleChangeText = (index: any, text: any) => {
    const updatedFields = [...fields];
    updatedFields[index] = text;
    setFields(updatedFields);

    if (text !== "" && index < refs.length - 1) {
      focusNextField(index);
    }
  };

  const handleKeyPress = (index: any, event: any) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      event.preventDefault(); // Prevent default backspace behavior
      const updatedFields = [...fields];
      updatedFields[index] = "";
      setFields(updatedFields);

      if (index > 0) {
        focusPrevField(index);
      }
    }
  };
  useEffect(() => {
    if (fields.every((field) => field !== "")) {
      // All fields have been filled, call verifyPin
      validateOtp();
      // navigation.navigate("CreateTagScreen", route.params)

      // setLoader(true)
    }
  }, [fields]);

  return (
    <section className="login-section">
      <div className="login-nav">
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
              stroke="white"
              strokeWidth="2"
            />
          </svg>
        </Link>
      </div>
      <div className="login-topper"></div>
      <div className="login-body">
        <div className="login-wrap">
          <p className="l-head-text">Verify email address</p>
          <p className="l-body-text">
            We sent a code to <span className="userMail">{email}</span> <br />
            To ensure that you have access to this account.
          </p>
          <form className="otp-form">
            <p>Enter the 6-digit code</p>
            <div className="otp-cont">
              {refs.map((ref, index) => (
                <div
                  key={index}
                  className="otp-input"
                  style={{
                    marginRight: index === refs.length - 1 ? 0 : "",
                    borderColor: fields[index] ? "rgba(71, 141, 206, 1)" : "",
                  }}
                >
                  <input
                    type="text"
                    maxLength={1}
                    // @ts-ignore
                    ref={ref}
                    onChange={(e) => handleChangeText(index, e.target.value)}
                    onKeyDown={(e) => handleKeyPress(index, e)}
                    value={fields[index]}
                  />
                </div>
              ))}
            </div>
            {seconds > 0 ? (
              <>
                <p className="resend-timer">
                  Resend code in {""}
                  <span className="seconds-timer">{seconds}</span>
                </p>
              </>
            ) : (
              <>
                <p
                  className="rsnd"
                  onClick={handleResend}
                  style={{ color: "#D4F36B", fontWeight: "500" }}
                >
                  {" "}
                  Resend OTP
                </p>
              </>
            )}
            {/* <p className="resend-timer">
              {seconds > 0
                ? `Resend code in ${(
                    <span className="seconds-timer">{seconds}</span>
                  )}`
                : !resendDisabled && (
                    <span
                      onClick={handleResend}
                      style={{ color: "#D4F36B", fontWeight: "500" }}
                    >
                      Resend OTP
                    </span>
                  )}
            </p> */}
            <div className="otp-btn-wrap">
              <div className="signIn-btn otp-btn" onClick={validateOtp}>
                Continue
              </div>
            </div>
          </form>
        </div>
      </div>
      <Toast />
    </section>
  );
}
