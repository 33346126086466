import React, { useState, useEffect } from "react";
import "../css/style.css";
import SideBar from "../components/SideBar";
import ProfileNav from "../components/ProfileNav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import FadeInSection from "../components/FadeIn";
import paperClip from "../assests/images/papperclip.svg";
import emptyDoc from "../assests/images/empty-doc.svg";
import Logout, { handleLogout } from "../components/Logout";
import CancelSub, { cancelSub } from "../components/CancelSub";
import checkmark from "../assests/images/checkmark.svg";
import dropDown from "../assests/images/dropdown.svg";
import dropUp from "../assests/images/dropUp.svg";
import star from "../assests/images/noto_star.svg";
import crCard from "../assests/images/crCard.svg";
import masterCard from "../assests/images/masterCard.svg";
import Input1 from "../components/Input1";
import visa from "../assests/images/visa.svg";
import AE from "../assests/images/AE.svg";
import Btn from "../components/Btn";
import { useMediaQuery } from "react-responsive";
import {
  GetSubscriptionPlan,
  GetUserDetails,
  Subscribe,
  ChangeSubscription,
} from "../RequestHandlers/User";
import ChangePlanScreen from "./ChangePlanScreen";
import { formatDate } from "../components/FormatDate";
import { CancelSubscribtion } from "../RequestHandlers/Subscription";
import Toast, { handleToast } from "../components/Toast";
import gX from "../assests/images/gx.svg";
import ChangePlanScreen2 from "./ChangePlaneScreen2";

const stripePromise = loadStripe(
  "pk_test_51PaDpS2NshWqVeHsJdQUYblI8SqKg5bPdx6smwMatDctOvQc72sQ9CALFykOPJspWLk370azczFrcqNYggEU6PV200mOapJP4s"
);

const PaymentForm = (subType: any, setPaymentInfo: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState("");
  const location = useLocation();

  const getUserDetails = async () => {
    console.log(setPaymentInfo);

    try {
      const userDetails = await GetUserDetails();
      console.log(subType, "subType");

      try {
        const sub = await Subscribe({
          subcriptionId: 1,
          userId: Number(userDetails.id),
          subcriptionType: subType.subType,
        });
        console.log();
        setClientSecret(sub.client_secret);
        // setPaymentInfo(sub);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function getBaseUrl() {
    const url = new URL(window.location.href);
    return `${url.protocol}//${url.host}/`;
  }

  // Example usage:

  useEffect(() => {
    // Fetch the client secret from your backend
    async function fetchClientSecret() {
      getUserDetails();

      // Replace with your API call
    }

    fetchClientSecret();
  }, []);
  useEffect(() => {
    // Fetch the client secret from your backend
    async function fetchClientSecret() {
      getUserDetails();

      // Replace with your API call
    }

    fetchClientSecret();
  }, [subType]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    const baseurl = getBaseUrl();
    elements.submit();
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: baseurl + "PaymentScreen",
      },
      clientSecret: clientSecret,
    });

    if (error) {
      console.log(error);

      if (error.type === "card_error" || error.type === "validation_error") {
        showMessage(error.message);
        handleToast(error.message, "error");
      } else {
        showMessage("An unexpected error occurred.");
      }
    }
  };

  const showMessage = (messageText: any) => {
    const messageContainer = document.querySelector("#payment-message");
    // @ts-ignore
    messageContainer.classList.remove("hidden");
    // @ts-ignore
    messageContainer.textContent = messageText;
    setTimeout(() => {
      // @ts-ignore
      messageContainer.classList.add("hidden");
      // @ts-ignore
      messageContainer.textContent = "";
    }, 4000);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        margin: 0,
      }}
    >
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        style={{ alignSelf: "center", width: "90%" }}
      >
        <PaymentElement
          id="payment-element"
          options={{ layout: "tabs" }}
          className=""
        />
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <button id="submit" className="pay-btn2">
            <div className="spinner hidden" id="spinner"></div>
            <span id="button-text">Pay now</span>
          </button>
        </div>
        <div id="payment-message" className="hidden"></div>
      </form>
    </div>
  );
};

export default function UserSupscriptionScreen() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const location = useLocation();
  const fromSupport = location.state?.fromBlog || false;
  const [subType, setSubType] = useState("monthly");
  const [cPasswordVisible, setCPasswordVisible] = useState(false);
  const [nPasswordVisible, setNPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [plan, setPlan] = useState("");
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [pop, setPop] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showChangePlan, setChangePlan] = useState(false);
  const [subInfo, setSubInfo] = useState({});
  const [PaymentInfo, setPayMentInfo] = useState({});
  const [showCancelSubPop, setShowCancelSubPop] = useState(false);
  const [showChangeCurrentPlanPop, setShowChangeCurrentPlanPop] =
    useState(false);
  const [currentPlanType, setCurrentPlanType] = useState("");
  const [uID, setUid] = useState(null);
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    // Example: Reset scroll position when navigating to this page
    window.scrollTo(0, 0);
  }, []);
  const subBenefit = [
    "Access subscriber-only content on TrendX",
    "Get blockchain investment recommendations",
    "Get timely and exclusive market insights",
    "Access to detailed blockchain case studies",
    "Read exclusive interviews with successful investors",
    "Achieve your goals in the blockchain space ",
  ];

  const getSubscriptionPlan = async () => {
    try {
      const subscription = await GetSubscriptionPlan();
      const subscriptionIsActive =
        subscription.plan.active == "1" ? true : false;
      setIsSubscribed(subscriptionIsActive);
      setSubInfo(subscription);
      console.log(subscription);
      console.log(subscription.subscriptionData.type);
    } catch (error) {
      console.error(error);
    }
  };

  const cancelSub = async () => {
    setShowCancelSubPop(false);
    try {
      // @ts-ignore
      const subCancelled = await CancelSubscribtion(subInfo.subcriptionData.id);
      console.log(subCancelled);
      if (subCancelled.status == true) {
        navigate(location.pathname, { replace: true });
        handleToast(subCancelled.message, "success");
        window.location.reload();
      } else {
        handleToast("unsuccessfull attempt", "error");
      }
      // @ts-ignore
      console.log(subInfo.subcriptionData.id);
    } catch (error) {
      console.log(error);
    }
  };

  const userDetails = async () => {
    try {
      const ud = await GetUserDetails();
      console.log(ud.id);
      setUid(ud.id);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSubPlan = async () => {
    try {
      const changePlan = await ChangeSubscription({
        // @ts-ignore
        subcriptionId: Number(subInfo.plan.id),
        userId: Number(uID),
        subcriptionType: currentPlanType,
      });
      console.log(changePlan);
      setTimeout(() => {
        console.log(currentPlanType, "plan type");
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    userDetails();
    getSubscriptionPlan();
  }, []);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "subscription",
        amount: 1099,
        currency: "usd",
        // Fully customizable with appearance API.
        appearance: {
          theme: "night",
        },
      }}
    >
      <section
        className="dashboard-layout"
        style={{ display: showChangePlan == true ? "none" : "" }}
      >
        <div className="left-layout">
          <SideBar logoutClick={() => handleLogout()} fromBlog={fromSupport} />
        </div>
        <div className="left-spacer"></div>
        <div className="body-layout" style={{ height: "auto" }}>
          <div className="profile-nav-cont">
            {isMobile ? (
              <>
                <div className="sub-profile-nav">
                  {" "}
                  <Link
                    to=" "
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    className="blogArrow-back"
                    style={{ marginTop: 0, paddingTop: 0 }}
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
                        stroke="white"
                        stroke-width="2"
                      />
                    </svg>
                  </Link>
                </div>
              </>
            ) : (
              <ProfileNav />
            )}
          </div>
          {/* <div className="profile-nav-topper"></div> */}
          <div className="main-body ppr-sec">
            {plan === "cancel" ? (
              <p
                className="pro-fp cancel-header-t"
                style={{ textAlign: "center" }}
              >
                Your subscription <br />
                has been cancelled
              </p>
            ) : (
              <p className="pro-fp" style={{ marginBottom: 24 }}>
                Subscription
              </p>
            )}

            {plan === "cancel" ? (
              <>
                <p className="user-sup-p cancel-p" style={{ marginBottom: 0 }}>
                  Your current subscription is active till
                </p>
                <p className="ex-date pro-fp cancel-h4">June 12, 2024</p>
              </>
            ) : (
              <></>
              // <p className="user-sup-p">
              //   You can change your plan, update your payment information, or
              //   cancel your subscription.
              // </p>
            )}

            <div className="asc-wrap">
              {/* current sub */}
              {isSubscribed === true ? (
                <>
                  <div
                    className="sub-page pay-plan sub-page1"
                    style={{ marginTop: 0, width: "100%" }}
                  >
                    <div className="modal-header">
                      <div className="mh-text-cont">
                        <div className="star-wrap">
                          <img src={star} alt="" />
                          <p>TX premium</p>
                        </div>
                        {/* @ts-ignore */}
                        {subInfo.subcriptionData.type === "monthly" ? (
                          <p className="price"> $4.99/month</p>
                        ) : (
                          <p className="price">
                            {" "}
                            <span className="old-price">$59.88</span>$11.88/year
                          </p>
                        )}

                        <p
                          className="next-py"
                          style={{ color: "rgba(232, 232, 232, 0.8)" }}
                        >
                          Next payment: {/* @ts-ignore */}
                          {formatDate(subInfo.subcriptionData.endDate)}
                        </p>
                      </div>
                      {/* <Link
                        className="change-plan"
                        to={""}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowChangeCurrentPlanPop(true);
                        }}
                        style={{ marginTop: -70 }}
                      >
                        Change plan
                      </Link> */}
                    </div>
                    <button
                      className="pay-dropdown"
                      onClick={handleDropdownToggle}
                    >
                      What’s included
                      <img
                        src={isDropdownOpen ? dropUp : dropDown}
                        alt=""
                        style={{ marginLeft: 12 }}
                      />
                    </button>
                    {isDropdownOpen && (
                      <div className="modal-body">
                        {subBenefit.map((item, index) => {
                          return (
                            <div
                              className="sub-ben"
                              style={{
                                marginBottom:
                                  index === subBenefit.length - 1 ? 0 : "",
                              }}
                            >
                              <img src={checkmark} alt="" />
                              <p className="py-sub-benefit">{item}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div
                    className="sub-page pay-plan sub-page1"
                    style={{ marginTop: 0, width: "100%", paddingBottom: 0 }}
                  >
                    <div
                      className="modal-header"
                      style={{ border: "none", marginBottom: "none" }}
                    >
                      <div className="mh-text-cont">
                        <div className="star-wrap">
                          <img src={crCard} alt="" />
                          <p>Payment method</p>
                        </div>
                        <p
                          className="price card-info"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            // @ts-ignore
                            src={subInfo.method.brand ? visa : masterCard}
                            style={{ width: 47, height: 32 }}
                            alt=""
                          />
                          {/* @ts-ignore */}
                          {subInfo.method.brand} ****{subInfo.method.last4}
                        </p>
                      </div>
                      {/* <Link
                        className="change-plan"
                        to={""}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/paymentInfo", {
                            state: { fromBlog: true },
                          });
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        Update
                      </Link> */}
                    </div>
                  </div>

                  <button
                    style={{ marginBottom: 20 }}
                    className="cancel-sub"
                    onClick={() => {
                      setShowCancelSubPop(true);
                    }}
                  >
                    Cancel subscription
                  </button>
                </>
              ) : isSubscribed === false ? (
                <>
                  {" "}
                  <div
                    className="sub-page pay-plan"
                    style={{ marginTop: 0, width: "100%" }}
                  >
                    <div className="modal-header">
                      <div className="mh-text-cont">
                        <div className="star-wrap">
                          <img src={star} alt="" />
                          <p>TX premium</p>
                        </div>
                        {subType === "monthly" ? (
                          <p className="price">$4.99/month</p>
                        ) : (
                          <p className="price">
                            {" "}
                            <span className="old-price">$59.88</span>$11.88/year
                          </p>
                        )}
                      </div>
                      <Link
                        className="change-plan"
                        to={""}
                        onClick={(e) => {
                          e.preventDefault();
                          // navigate("/changePlan", {
                          //   state: { fromBlog: true },
                          // });
                          setChangePlan(true);
                        }}
                      >
                        Change plan
                      </Link>
                    </div>
                    <button
                      className="pay-dropdown"
                      onClick={handleDropdownToggle}
                    >
                      What’s included
                      <img
                        src={isDropdownOpen ? dropUp : dropDown}
                        alt=""
                        style={{ marginLeft: 12 }}
                      />
                    </button>
                    {isDropdownOpen && (
                      <div className="modal-body">
                        {subBenefit.map((item, index) => {
                          return (
                            <div
                              className="sub-ben"
                              style={{
                                marginBottom:
                                  index === subBenefit.length - 1 ? 0 : "",
                              }}
                            >
                              <img src={checkmark} alt="" />
                              <p className="py-sub-benefit">{item}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="pcd-cont">
                    <p>Pay with credit or debit card</p>
                  </div>
                  <div
                    className="sub-page sub-p"
                    style={{ marginTop: 0, width: "100%" }}
                  >
                    <div
                      className="modal-header py-modal-header"
                      style={{ border: "none", justifyContent: "flex-start" }}
                    >
                      <img className="brand-logo" src={masterCard} alt="" />
                      <img className="brand-logo" src={visa} alt="" />
                      <img className="brand-logo" src={AE} alt="" />
                    </div>
                    {/* <div
                      className="modal-header py-modal-header"
                      style={{ border: "none", justifyContent: "flex-start" }}
                    >
                      <img className="brand-logo" src={masterCard} alt="" />
                      <img className="brand-logo" src={visa} alt="" />
                      <img className="brand-logo" src={AE} alt="" />
                    </div>

                    <div className="py-inputs-cont">
                      <Input1
                        label="Card number"
                        placeholder="0000 0000 0000 0000"
                        labelStyle={{ fontWeight: "400" }}
                        inputWrapStyle={{ height: 56 }}
                      />
                      <div className="cvv-cont">
                        <div className="cvv-input-cont1">
                          <Input1
                            label="Expiration"
                            placeholder="MM/YY"
                            labelStyle={{ fontWeight: "400" }}
                            inputWrapStyle={{ height: 56 }}
                          />
                        </div>
                        <div className="cvv-input-cont1">
                          <Input1
                            label="Security Code (CVV)"
                            placeholder="000"
                            labelStyle={{ fontWeight: "400" }}
                            inputWrapStyle={{ height: 56 }}
                          />
                        </div>
                      </div>

                      <button
                        className="pay-btn"
                        onClick={() => {
                          setPop(true);
                        }}
                      >
                        Pay now
                      </button>
                      <p className="py-method">
                        Your payment method will be charged a recurring{" "}
                        <span>$11.88 yearly</span> fee, unless you decide to
                        cancel. No refunds for subscriptions canceled between
                        billing cycles.
                      </p>
                    </div> */}
                    <PaymentForm
                      subType={subType}
                      setPaymentInfo={"setPayMentInfo"}
                    />
                  </div>
                </>
              ) : plan === "cancel" ? (
                <>
                  <p className="ex-text">
                    After this date you will not be charged a subscription fee.
                    <br />
                    There are no refunds for subscriptions cancelled between
                    billing cycles.
                    <br />
                    If you change your mind you can always re-activate your
                    subscription.
                  </p>
                  <Btn
                    specialBtnClass="cans-btn"
                    text="Re-activate subscription"
                    onclick={() => {
                      navigate("/reactivatePlan", {
                        state: { fromBlog: true },
                      });
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <Logout />
        {showCancelSubPop && (
          <div className="logoutPop">
            <div className="logout-card">
              <img src={gX} alt="" onClick={() => setShowCancelSubPop(false)} />
              <p className="log-text1">Cancel subscription?</p>
              <p className="log-text2">
                Are you sure you want to cancel your subscription? Your current
                subscription will be canceled and your card details will be
                deleted.
              </p>
              <div className="log-btn-wrap">
                <button
                  className="log-control-btn"
                  onClick={() => setShowCancelSubPop(false)}
                >
                  Go back
                </button>
                <button
                  className="log-control-btn"
                  style={{ background: "#fff" }}
                  onClick={cancelSub}
                >
                  Yes, cancel
                </button>
              </div>
            </div>
          </div>
        )}
        <Toast />
      </section>
      {showChangePlan && (
        <ChangePlanScreen
          setSubType={setSubType}
          setChangePlan={setChangePlan}
        />
      )}
      {showChangeCurrentPlanPop && (
        <ChangePlanScreen2
          setSubType={setCurrentPlanType}
          setChangePlan={setShowChangeCurrentPlanPop}
          selectMonthly={() => {
            setCurrentPlanType("monthly");
            setTimeout(() => {
              changeSubPlan();
            }, 1000);
          }}
          selectYearly={() => {
            setCurrentPlanType("yearly");

            setTimeout(() => {
              changeSubPlan();
              console.log(currentPlanType, "p2");
            }, 1000);
          }}
          // @ts-ignore
          currentPlan={subInfo.subcriptionData.type}
        />
      )}
    </Elements>
  );
}
