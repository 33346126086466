import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "../css/style.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import star from "../assests/images/noto_star.svg";
import checkmark from "../assests/images/checkmark.svg";
import dropDown from "../assests/images/dropdown.svg";
import dropUp from "../assests/images/dropUp.svg";
import masterCard from "../assests/images/masterCard.svg";
import visa from "../assests/images/visa.svg";
import AE from "../assests/images/AE.svg";
import Input1 from "../components/Input1";
import PopModal from "../components/PopModal";
import { AddCard, GetUserDetails, Subscribe } from "../RequestHandlers/User";
import Toast, { handleToast } from "../components/Toast";

const stripePromise = loadStripe(
  "pk_test_51PaDpS2NshWqVeHsJdQUYblI8SqKg5bPdx6smwMatDctOvQc72sQ9CALFykOPJspWLk370azczFrcqNYggEU6PV200mOapJP4s"
);

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState("");
  const location = useLocation();

  function getBaseUrl() {
    const url = new URL(window.location.href);
    return `${url.protocol}//${url.host}/`;
  }

  const getUserDetails = async () => {
    try {
      const userDetails = await GetUserDetails();
      console.log(userDetails);
      console.log(location.state?.subscriptionType || "e no dey");

      try {
        const sub = await Subscribe({
          subcriptionId: 1,
          userId: Number(userDetails.id),
          subcriptionType: location.state?.subscriptionType || "",
        });
        console.log();
        setClientSecret(sub.client_secret);
        // console.log(sub);

        console.log(sub);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Fetch the client secret from your backend
    async function fetchClientSecret() {
      getUserDetails();

      // Replace with your API call
    }

    fetchClientSecret();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    const baseurl = getBaseUrl();
    elements.submit();
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: baseurl + "PaymentScreen",
      },
      clientSecret: clientSecret,
    });

    if (error) {
      console.log(error);
      if (error.type === "card_error" || error.type === "validation_error") {
        showMessage(error.message);
        handleToast(error.message, "error");
      } else {
        showMessage("An unexpected error occurred.");
        handleToast(error.message, "error");
      }
    }
  };

  const showMessage = (messageText: any) => {
    const messageContainer = document.querySelector("#payment-message");
    // @ts-ignore
    messageContainer.classList.remove("hidden");
    // @ts-ignore
    messageContainer.textContent = messageText;
    setTimeout(() => {
      // @ts-ignore
      messageContainer.classList.add("hidden");
      // @ts-ignore
      messageContainer.textContent = "";
    }, 4000);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        margin: 0,
      }}
    >
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        style={{ alignSelf: "center", width: "90%" }}
      >
        <PaymentElement
          id="payment-element"
          options={{ layout: "tabs" }}
          className=""
        />
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <button id="submit" className="pay-btn2">
            <div className="spinner hidden" id="spinner"></div>
            <span id="button-text">Pay now</span>
          </button>
        </div>
        <div id="payment-message" className="hidden"></div>
      </form>
    </div>
  );
};

const StripePayment = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [plan, setPlan] = useState("yearly");
  const [pop, setPop] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const location = useLocation();

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    setPlan(location.state?.subscriptionType);
    window.scrollTo(0, 0);
  }, []);

  const subBenefit = [
    "Access subscriber-only content on TrendX",
    "Get blockchain investment recommendations",
    "Get timely and exclusive market insights",
    "Access to detailed blockchain case studies",
    "Read exclusive interviews with successful investors",
    "Achieve your goals in the blockchain space ",
  ];

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "subscription",
        amount: 1099,
        currency: "usd",
        // Fully customizable with appearance API.
        appearance: {
          theme: "night",
        },
      }}
    >
      <section className="blogPostSection">
        <div className="blog-nav blog-post-nav">
          <Link
            to=" "
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="blogArrow-back"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </Link>
        </div>
        <div className="sub-topper"></div>
        <div className="bpb-section">
          <div className="bpb-inner-wrap py-inner-wrap">
            <p className="sub-text-head pay">Payment</p>
            <div
              className={"active-page-cont"}
              style={{ flexDirection: "column" }}
            >
              <div
                className="sub-page pay-plan"
                style={{ marginTop: 0, width: "100%" }}
              >
                <div className="modal-header">
                  <div className="mh-text-cont">
                    <div className="star-wrap">
                      <img src={star} alt="" />
                      <p>TX premium</p>
                    </div>
                    {plan === "monthly" ? (
                      <p className="price">$4.99/month</p>
                    ) : (
                      <p className="price">
                        {" "}
                        <span className="old-price">$59.88</span>$11.88/year
                      </p>
                    )}
                  </div>
                  <Link className="change-plan" to={"/subscriptionScreen"}>
                    Change plan
                  </Link>
                </div>
                <button className="pay-dropdown" onClick={handleDropdownToggle}>
                  What’s included
                  <img
                    src={isDropdownOpen ? dropUp : dropDown}
                    alt=""
                    style={{ marginLeft: 12 }}
                  />
                </button>
                {isDropdownOpen && (
                  <div className="modal-body">
                    {subBenefit.map((item, index) => {
                      return (
                        <div
                          className="sub-ben"
                          style={{
                            marginBottom:
                              index === subBenefit.length - 1 ? 0 : "",
                          }}
                        >
                          <img src={checkmark} alt="" />
                          <p className="py-sub-benefit">{item}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="pcd-cont">
                <p>Pay with credit or debit card</p>
              </div>
              <div className="sub-page" style={{ marginTop: 0, width: "100%" }}>
                <div
                  className="modal-header py-modal-header"
                  style={{ border: "none", justifyContent: "flex-start" }}
                >
                  <img className="brand-logo" src={masterCard} alt="" />
                  <img className="brand-logo" src={visa} alt="" />
                  <img className="brand-logo" src={AE} alt="" />
                </div>
                <PaymentForm />
                {/* <div className="py-inputs-cont">
                <Input1
                  label="Cardholder Name"
                  placeholder="Cardholder Name"
                  labelStyle={{ fontWeight: "400" }}
                  inputWrapStyle={{ height: 56 }}
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                />
                <CardElement options={cardStyle} />
                <button className="pay-btn" onClick={handleSubmit}>
                  Subscribe
                </button>
                <p className="py-method">
                  Your payment method will be charged a recurring{" "}
                  <span>$11.88 yearly</span> fee, unless you decide to cancel.
                  No refunds for subscriptions canceled between billing cycles.
                </p>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <Toast />
      </section>
    </Elements>
  );
};

export default StripePayment;
